<template>
  <div class="page1">
    <!--主体内容-->
    <div class="contentRoadsideEdit">
      <h2 class="title">
        <div class="title_icon">
        </div>
        编辑NVR设备
      </h2>
      <!--搜索条件区域-->
      <div class="Wrapper" @keydown.enter.prevent="submitData">
        <el-form label-position="right" label-width="120px" :model="formInline"
                 style="width: 320px; float:left;" :rules="rules" :ref="'form'">
          <el-form-item :label="$t('searchModule.Equipment_name')" prop="monitorEquipmentName">
            <el-input :maxlength='20' v-model.trim="formInline.monitorEquipmentName"
                      placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Equipment_type')" prop="monitorEquipmentType">
            <el-select v-model.trim="formInline.monitorEquipmentType">
              <el-option
                label="HK-海康"
                :value="1"
              ></el-option>
              <el-option
                label="DH-大华"
                :value="2"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Belonging_operator')" prop="operationId">
            <el-select
              v-model.trim="formInline.operationId"
              filterable
              size="15"
              placeholder="请选择"
              @change="
                $refs.parkInput.setValue('');
                formInline.parkId = '';
              "
            >
              <!-- <el-option label="全部" value=""></el-option> -->
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in roleList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Belonging_parking_lot')" prop="parkId">
            <auto-complete ref="parkInput" slaveRelations='0,1,2,3,4'
                           :operationId="formInline.operationId"
                           @valueChange='completeValue'></auto-complete>
          </el-form-item>
          <el-form-item label="经纬度" prop="longitude">
            <el-input v-model.trim="lnglat" placeholder="在地图上选择坐标点" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.address')" prop="address">
            <el-input :maxlength='50' type="textarea" disabled v-model.trim="formInline.address"
                      placeholder="输入车场详细地址"></el-input>
          </el-form-item>
          <el-form-item label="流媒体协议" prop="protocolType">
            <el-select v-model.trim="formInline.protocolType">
              <el-option
                label="RTSP"
                :value="1"
              ></el-option>
              <el-option
                label="GB28181"
                :value="2"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="IP地址" prop="ipAddress" v-if="formInline.protocolType === 1">
            <el-input :maxlength='15' v-model.trim="formInline.ipAddress"
                      placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="端口号" prop="ipPort" v-if="formInline.protocolType === 1">
            <el-input :maxlength='10' v-model.trim="formInline.ipPort"
                      placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="视频端口号" prop="monitorPort" v-if="formInline.protocolType === 1">
            <el-input :maxlength='10' v-model.trim="formInline.monitorPort"
                      placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="用户名" prop="account" v-if="formInline.protocolType === 1">
            <el-input :maxlength='20' v-model.trim="formInline.account"
                      placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" v-if="formInline.protocolType === 1">
            <el-input :maxlength='20' v-model.trim="formInline.password"
                      placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label-width="90px" label="GB设备号" prop="videoEquipmentCode" v-if="formInline.protocolType === 2">
            <el-tooltip placement="top" class="tooltip1">
              <div slot="content">
                由云杉服务方提供
                <br />
              </div>
              <span class="help">?</span>
            </el-tooltip>
            <el-input style="width:200px" :maxlength='20' v-model.trim="formInline.videoEquipmentCode"
                      placeholder="请输入内容"></el-input>
          </el-form-item>
          <!-- <el-form-item label="设备号" prop="monitorEquipmentCode">
            <el-input v-model.trim="formInline.monitorEquipmentCode"
                      placeholder="请输入内容"></el-input>
          </el-form-item> -->
        </el-form>
      </div>
      <div style="text-align: left;  margin-left: 8.5%; ">
        <el-button type="primary" @click="submitData" style="width: 88px;">{{ $t('button.preservation') }}</el-button>
        <el-button type="" @click="$router.go(-1)" style="width: 88px;">取消</el-button>
      </div>
    </div>
    <map-con ref='mapConpent' @mapCancel='mapCancel' @mapSure='mapSure'></map-con>
  </div>
</template>
<script>
import autoComplete from '@/components/autocomplete/autocompletaRoadside';
import mapCon from "@/components/map";
export default {
  name: 'editVideoMonitorEquipRoadside',
  data() {
    let checkIP = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      /* eslint-disable */
      if (!/^[\d\.]+$/.test(value)) {
        return callback('ip地址格式不正确！')
      }
      if (value > 65535) {
        callback(new Error('IP地址应小于65535!'));
      }
      /* eslint-disable */
      return callback();
    }
    let checkPort = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      if (!/^[\d]+$/.test(value)) {
        return callback('端口号必须为数字！')
      }
      if (value > 65535) {
        callback(new Error('端口号应小于65535!'));
      }
      return callback();
    }
    let checkmonitorPort = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      if (!/^[\d]+$/.test(value)) {
        return callback('视频端口号必须为数字！')
      }
      if (value > 65535) {
        callback(new Error('视频端口号应小于65535!'));
      }
      return callback();
    }
    return {
      roleList: [],
      parkPage: 1,
      parkName: '',
      dis: false,
      lnglat: '',
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },
      parkData: [],
      parkTypeAll: [],
      parkList: '',
      areaList: [],
      isEdit: false,
      loading: false,
      startTime: '',
      formInline: {
        parkId: '',
        monitorEquipmentName: '',
        monitorEquipmentType: '',
        address: '',
        latitude: '',
        longitude: '',
        protocolType: 1,
        ipAddress: '',
        ipPort: '',
        monitorPort: '',
        account: '',
        password: '',
        operationId: '',
        // monitorEquipmentCode: '',
        videoEquipmentCode: ''
      },
      rules: {
        parkId: [{
          required: true,
          message: '必填',
          trigger: ['blur', 'change']
        }],
        operationId: [{
          required: true,
          message: '必填',
          trigger: ['blur', 'change']
        }],
        monitorEquipmentName: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }],
        protocolType: [{
          required: true,
          message: '必填',
          trigger: ['blur', 'change']
        }],
        ipAddress: [{
          required: true,
          message: '必填',
          trigger: 'blur',
        }, {
          validator: checkIP, trigger: 'blur'
        }],
        ipPort: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }, {
          validator: checkPort, trigger: 'blur'
        }],
        monitorPort: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }, {
          validator: checkmonitorPort, trigger: 'blur'
        }],
        monitorEquipmentType: [{
          required: true,
          message: '必填',
          trigger: ['blur', 'change']
        }],
        address: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }],
        latitude: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }],
        longitude: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }],
        account: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }],
        // monitorEquipmentCode: [{
        //   required: true,
        //   message: '必填',
        //   trigger: 'blur'
        // }],
        videoEquipmentCode: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }]
      }
    }
  },
  methods: {
    completeValue(item) {
      this.formInline.parkId = item;
      if (!item) return;
      this.getParkDetail(item)
    },
    getParkDetail(parkId) {
      //  let url = '/acb/2.0/park/' + parkId
      let url = '/acb/2.0/bacb/park/getParkVOById/' + parkId
      this.$axios.get(url).then(res => {
        if (res.state == 0) {
          let data = res.value;
          this.lnglat = data.longitude / 1000000 + ',' + data.latitude / 1000000;
          this.formInline.latitude = data.latitude;
          this.formInline.longitude = data.longitude;
          this.formInline.address = data.parkAddress;
          // this.treeData = this.sortArea(res.value);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          })
        }
      })
    },
    openMap() {
      this.$refs.mapConpent.openMap(this.lnglat);
    },
    mapCancel() {
      this.$refs.mapConpent.mapClose();
      // this.formInline.parkAddress = '';
      // this.lnglat = '';
    },
    mapSure(opt) {
      this.formInline.latitude = opt.temLat * 1000000;
      this.formInline.longitude = opt.temLng * 1000000;
      this.formInline.address = opt.parkAddress;
      this.lnglat = opt.hasSelect;
      this.$refs.mapConpent.mapClose();
    },
    getDetail() {
      // /bacop/monitorEquipment/detail
      this.$axios.get('/acb/2.0/bacb/monitorEquipment/detail/' + this.$route.query.monitorEquipmentId)
        .then(res => {
          if (res.state == 0) {
            for (let i in this.formInline) {
              this.formInline[i] = res.value[i];
            }
            this.lnglat = this.formInline.longitude / 1000000 + ',' + this.formInline.latitude / 1000000;
            if (this.formInline.parkId) {
              this.$refs.parkInput.setShowVal(res.value.parkName);
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            })
          }
        })
    },
    submitData() {
      this.$refs['form'].validate((valid) => {
        //gb时将rtmp协议的数据滞空
        if (this.formInline.protocolType === 2){
          this.formInline.ipAddress = '';
          this.formInline.ipPort = '';
          this.formInline.monitorPort = '';
          this.formInline.account = '';
          this.formInline.password = '';
        }else{
          this.formInline.videoEquipmentCode = '';
        }
        if (valid) {
          this.$axios.post('/acb/2.0/bacb/monitorEquipment/update', {
            data: {
              monitorEquipmentId: this.$route.query.monitorEquipmentId,
              ...this.formInline
            }
          })
            .then(res => {
              if (res.state == 0) {
                this.$alert('操作成功！', this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
                }).then(() => {
                  this.$router.go(-1);
                })
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
                })
              }
            })
        } else {
          return false;
        }
      });
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      this.$axios
        .get("/acb/2.0/operation/nameList", {
          data: {
            page: 0,
            pageSize: 0,
            companyName: "",
            bearSide: ""
          }
        })
        .then(res => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        });
    },
  },
  components: {
    mapCon,
    autoComplete
  },
  created() {
    this.getRoleList();
    this.getDetail();
  },
  mounted() {
  },
  computed: {}
}
</script><!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.Wrapper
  overflow hidden
.breadcrumb
  height: 35px;
.contentRoadsideEdit
  background: #FFFFFF;
  overflow: hidden;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;
.uploadPic
  float: left;
  margin-left: 150px;
.tooltip1 {
  display: inline-block;
  margin-right: 10px;
}
.help {
  display: inline-block;
  border-radius: 10px;
  text-align: center;
  color: white;
  width: 20px;
  line-height: 20px;
  vertical-align: middle;
  background: #c0ccda;
  position: relative;
}
</style>
<style>
.avatar-uploader2 .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader2 .el-upload:hover {
  border-color: #0f6eff;
}
.avatar-uploader-icon2 {
  font-size: 28px;
  color: #8c939d;
  width: 295px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar2 {
  width: 295px;
  height: 178px;
  display: block;
}
</style>
